.container.error {
	padding:3rem;
	text-align: center;
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	svg {
		margin: 1rem auto;
		.logo-solid-color {
			fill: var(--rflix-foreground); 
		}
	}
}