@import "~bootstrap/scss/functions";

$background: #000000;
$foreground: #ffffff;
$primary: #947E56;
$secondary: #291036;
$tertiary: #291036;
$eggshell: #f3ebd7;


/*
291036
371442
947E56
A08C63
AC9A70
F3EBD7
767676
*/
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1230px
) !default;

$spacer: 16px;
$base-content-width: $spacer * 70;
$btn-padding-x: 1.5rem;
$page-padding: $spacer * 2;
$grid-gutter-width: 3.5rem;
$border-radius: 0.5rem;

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins";

$gray: $gray-500;

@mixin box-shadow-standard {
  box-shadow: $box-shadow-sm;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: $box-shadow;  
  }
}

@mixin auth-form-card {
  padding: 2rem;
  max-width: calc( $base-content-width / 3 );
  margin: 2rem auto 3rem auto;
  button {
    width:100%;
    margin-bottom: 0.5rem;
    text-align: center;
  }
}

@function set-tint-or-shade($color) {  
  @if (lightness( $color ) < 50) {
    @return lighten($color, 7%);
  }
  @else {
    @return darken($color, 7%);
  }
}