@import '../../scss/variables.scss';

.site-navigation {
	/*font-size: 0.875em;*/
	font-size: 1em;
	font-weight: 400;
	height:3.75rem;
	padding:0.625rem 1.875rem;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
	.btn {
		border:1px solid  var(--rflix-background);
		font-size:inherit;
	}
	.site-branding {
		white-space: nowrap;
		padding-top:5px;
		flex-grow: 1;
		svg {
			height:1.875rem;
			width:10.1rem;
			.logo-solid-color {
				fill: var(--rflix-foreground);
			}
		}
		img {
			height:1.5rem;
		}
	}
	h3 {
		margin:0 0 0 0;
		a {
			text-decoration: none;
		}
	}
	p {
		margin:1em 0;
		flex-grow: 0;
	}
	/*
	&.logged-in.active {
		nav {
			width: 50%;
			max-width: calc( 50% - 100px );
		}
	}
	*/
	nav {
		
		font-family: 'Julius Sans One', sans-serif;
		
		.loading_fragment {
			color: var(--rflix-background);
		}
		display: none;


		& > ul > li:first-child {
			margin-left:0;
		}
		& > ul > li:last-child {
			margin-right:0;
		}
		& > ul > li > a {
			color: var(--rflix-foreground);
		}
		margin:0;
		align-items: center;
		&.logged-in {
			justify-content: flex-start;
			&>ul>li {
				height:40px;
				line-height: 40px;
				li {
					height:auto;
					line-height: 20px;
				}
			}
		}
		&.top-right {
			justify-content: flex-end;
			margin-left: $spacer;
		}
		&.mobile-navigation {
			display: block;
		}
		& > ul {
			flex-wrap: nowrap;
			& > li {
				white-space: nowrap;
				& > span {
					color: var(--rflix-foreground);
					cursor: default;
				}
			}
			li ul {
				flex-wrap:wrap;
				& > li {
					white-space: normal;
	
				}
			}
		}
		.woocommerce-MyAccount-navigation {
			li a { 
				color: var(--rflix-foreground);
				white-space: nowrap; 
			}
		}
		.search {
			border: 1px solid rgba(219, 223, 227, 0.5);
			height: 2.5rem;
			border-radius: $border-radius;
			line-height: 100%;
			vertical-align: middle;
			input {
				vertical-align: middle;
				background-color: transparent;
				border:none;
				height: 100%;
				border-radius: $border-radius;
				padding-left:1rem;
				color:  var(--rflix-foreground);
				&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
					color: var(--rflix-foreground);
					opacity: 1; /* Firefox */
				}
				&:-ms-input-placeholder { /* Internet Explorer 10-11 */
					color: var(--rflix-foreground);
				}
				&::-ms-input-placeholder { /* Microsoft Edge */
					color: var(--rflix-foreground);
				}
				&:focus {
					outline: none;
				}
			}
			button {
				border:none;
				padding:0 0.5rem;
				height: 100%;
				display: inline-block;
				&:focus {
					box-shadow: none;
				}
			}
		}
		ul {
			list-style: none;
			margin:0;
			padding:0;
			display: flex;
			align-items: center;
			li {
		  		margin:0 0.5em;
		  		a {

		  			text-decoration:none;
		  			&.active {
		  				text-decoration: underline;
		  			}
		  		}
	  		}
	  		li.has_children {
	  			position:relative;
	  			& > span {
	  				margin-right: 0.2em;
	  			}
	  			.loading_fragment {
	  				display: none;
	  			}
	  			& svg {
	  				transition: transform 0.5s;
	  				path {
						fill: var(--rflix-foreground);
					}
					font-size: 1.75em;

	  			}
	  			&:hover svg.rotate {
	  				transform: rotate(180deg) !important;
	  			}
	  			div {
	  				border-radius: $border-radius;
	  				box-shadow: 0 0 12px rgba(var(--rflix-primary-rgb), 0.5); 
	  				position:absolute;
		  			margin-left:-999999px;
		  			padding-top:0.5rem;
	  				z-index: 1001;
	  				& > ul {
		  				
						column-gap:1em;
		  				&:before {
		  					content: '';
		  					width: 0; 
							height: 0; 
							border-left: 0.5rem solid transparent;
							border-right: 0.5rem solid transparent;
							border-bottom: 0.5rem solid var(--rflix-background);
							position:absolute;
							top:0rem;
							left:1rem;
		  				}
		  				border-radius: $border-radius;
				  		padding:1.875rem;
		  				display: block;
		  				//background-color: darken( $background, 7% );
		  				background-color: var(--rflix-background);
		  				box-shadow: $box-shadow;
		  				opacity:0;
		  				transition: opacity 0.2s;
		  				li {
		  					a {
		  						/*white-space: nowrap;*/
		  					}
		  					&:hover a {
		  					}
		  				}
		  			}
	  			}

	  			
	  			&:hover div {
	  				margin-left: 0;
	  			}
	  			&:hover div > ul {
					opacity:1;
	  			}
	  			a:hover {
	  				text-decoration: underline;
	  			}
	  	
	  			&.my_account {
	  				margin-left: 0;
	  				div > ul {
	  					&:before {
	  						left: 50%;
	  					}
	  					li {
	  						margin-bottom: 1rem;
	  						&:last-child {
	  							margin-bottom: 0;
	  						}
	  					}
	  				}
	  				.my-account-icon {
	  					display: inline-block;
	  					border:1px solid var(--rflix-foreground);
	  					border-radius: 50%;
	  					width: 2.25rem;
	  					height: 2.25rem;
	  					line-height: 2rem;
	  					text-align: center;
	  					svg {
	  						display: inline-block;
	  						shape-rendering: geometricPrecision;
	  					}
	  				}

	  				&:hover { 
		  				.woocommerce-MyAccount-navigation {
		  					left:initial;
		  					right:-1em;
		  				}
	  				}
	  				svg {
	  					font-size: ($spacer * 1.5);
	  				}
	  			}
	  			&.megamenu {

	  				margin-right: 0;
	  				div > ul {
	  					display: flex;

			  			align-items:stretch;
			  			justify-content: space-between;
			  			flex-wrap: nowrap;
			  			& > li {
			  				display:block;
			  				margin:0;
			  				padding:0;
			  				a {
			  					color:  var(--rflix-foreground);
			  				}
			  			}
	  				}

	  				&.facilitators-menu div > ul {
		  				
		  				width: calc( 600px + ( $spacer * 5 ) );
		  				padding:$spacer ( $spacer / 2 ) 0;
		  				flex-wrap: wrap;
		  				li {
		  					box-sizing:border-box;
		  					text-align:center;
			  				width: calc( 33.33% - (2em / 3) );
			  				padding: 0 ( $spacer / 2 ) $spacer;
			  				a {
			  					text-align: left;
					  			display: flex;
					  			align-items:center;
					  			white-space: normal;
					  			img {
					  				width:60px;
					  				border-radius: $border-radius; 
					  				display:block;
					  				margin:0 1rem 0 0;
					  				@include box-shadow-standard;
					  			}
		  					}
		  				}
					}
		  			
					&.videos-menu div > ul {
		  				width:auto;
		  				& > li {
		  					& > ul {
		  						& > li {
		  							margin: 0 0 1rem 0;
		  							padding: 0;
		  						}
		  					}
		  					ul.durations {
		  						width:120px;
		  						display: block;
		  					}
							ul.skill_levels {
		  						width:130px;
		  						display: block;
		  					}
							ul.relationship_status {
		  						width:150px;
		  						display: block;
		  					}
							ul.gender {
		  						width:130px;
		  						display: block;
		  					}

		  					

		  					ul.latest_videos {
		  						display: flex;
		  						justify-content: space-between;
		  						align-items: flex-start;
		  						width:380px;
		  						column-gap:2em;
		  						& > li {
		  							&.latest-video {
		  								padding-right: $spacer / 2;
		  								img {
		  									border:1px solid;
		  								}
		  							}

		  							width: calc(50% - 1em);
		  							padding: 0px;
		  							a {
		  								display: block;
		  								white-space: normal;
		  							}
		  							img {
		  								display: block;
		  								max-width: 100%;
		  							}
		  						}
		  					}
		  				}
					}
					li.menu-header {
	  					width: 100%;
	  					font-weight: 700;
	  					letter-spacing: 0em;
	  					text-transform: uppercase;
	  					margin-bottom: $spacer !important;
	  					color: var(--rflix-primary);

	  					&.menu-header-right {
	  						text-align: right;
	  					}
	  					&.view-all {
	  						a {
	  							color: var(--rflix-primary);
	  							text-decoration: underline;
	  							font-weight: normal;
	  							text-transform: none;
	  						}
	  					}

	  				}
				}
	  		}
		}
		/*
		&.logged-in {
			.videos-menu {
  				position: relative;
  				&:hover div {
  					left:0;
  				}
  			}
		}
		*/
	}
}



@include media-breakpoint-up(md) {
	.site-navigation:not(.logged-in) {
		nav {
			display: flex;
			&.mobile-navigation {
				display: none;		
			}
		}		
	}
}

@include media-breakpoint-up(xl) {
	.site-navigation {
		nav {
			display: flex;
			&.mobile-navigation {
				display: none;		
			}
		}		
	}
}

/*
@media (max-width: 1480px) {
	.site-navigation.logged-in.active {
		nav {
			max-width: initial;
			width: auto;
			.separator {
				margin: 0 0.875rem;
			}
			li.my_account {
				.my-account-icon {
					margin-right:0.4rem;
				}
			}
		}
	}
}
@media (max-width: 1220px) {
	.site-navigation.logged-in.active {
		nav {
			.separator {
				display: none;
			}
			li.my_account {
				margin-left: 0.5em;
			}
		}
		.site-branding {
			margin:0 0.5rem;
		}
	}
}
*/