@import '../../scss/variables.scss';

$mobile_nav_width: 75%;

.mobile-nav-open {
	#root {
	}
	#App {
		height:100vh;
		overflow: hidden;
	}
}
.site-navigation {
	nav.mobile-navigation {
		position:absolute;
		top:0; 
		right:0;
		bottom: 0;
		pointer-events: none;
		width: 100% !important;
		max-width: 100% !important;
		ul > li > a {
			color: var(--rflix-foreground);
			text-decoration: none;
		}
		.mobile-navigation-background-wrapper {
			
			position: absolute;
			top: 60px;
			right: 0;
			bottom: 0;
			width: 100%;
			filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.3));
			z-index: 1001;
			transition: backdrop-filter 1s, background-color 0.2s;
			max-height: 100%;
			.background {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: $mobile_nav_width;
				background: $background;
				z-index: 1001;
				max-height: 100%;
				max-width: 360px;
			}
		}

		&.closed {
			ul {
				overflow-y: hidden;
			}
		}
		&.open {
			ul {
				overflow-y: scroll;
				scrollbar-width: thin;
				&::-webkit-scrollbar {
				    height: 8px;
				    width: 5px;
				    background: lighten($background,10%);
				}
				&::-webkit-scrollbar-thumb {
				    background: $primary;
				    -webkit-border-radius: 1ex;
				    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
				}
				&::-webkit-scrollbar-corner {
				    background: #000;
				}
			}
			pointer-events: all;
			.mobile-navigation-background-wrapper {
				background-color: transparentize($primary, 0.8);
				backdrop-filter: blur(3px);
			}
		}


		button {
			pointer-events: all;
			outline: none;
			border: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			cursor: pointer;
			position: absolute;
			top: 0.5rem;
			right: 1rem;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: transparent;
			z-index: 1001;
			color:#fff;
			svg {
				path {
					fill: var(--rflix-foreground);
					stroke:  var(--rflix-foreground);
				}
			}
		}

		ul,
		li {
			margin: 0;
			padding: 0;

		}
		&.open {
			pointer-events: all;
		}

		ul {
			padding: 25px 25px 25px 25px;
			display: flex;
			position: absolute;
			flex-direction: column;
			align-items: flex-start;
			top: 60px;
			right:0px;
			width: $mobile_nav_width;
			z-index: 1001;
			overflow-y: scroll;
			height:100%;
			max-height: calc( 100% - 60px );
			max-width: 360px;
			li {
				width: 100%;
				list-style: none;
				display: block;
				align-items: center;
				cursor: pointer;
				&.spacer {
					flex-grow: 1;
				}
				&.static {
					font-size: 1em;
					font-weight: normal;
					border:none;
					padding-bottom: 0;
					padding: 1rem 0 0 0;

				}
				&.dynamic {
					border-bottom:1px solid $gray-500;
					font-size: 1.5em;
					font-weight:bold;
					padding: 1rem 0;
				}
				&.dynamic:last-of-type {
					border-bottom: none;
				}
				a {
					text-decoration: none;
				}
				&.mobile-navigation-my-account {
					font-size: 1em;
					font-weight: normal;
					border:none;
					padding-bottom: 0;
					a {
						display: flex;
						align-items: center;
						color:$primary;

						.my-account-icon {
							display: inline-block;
							width: 2.25rem;
							height: 2.25rem;
							line-height: 2.25rem;
							border-radius: 50%;
							background: lighten($primary, 70%);
							text-align: center;
							margin-right: 0.5rem;
							svg {
								margin: 0 auto;
								path {
									fill: $primary;
								}
							}

						}
					}
				}
				&.mobile-navigation-logout, &.mobile-navigation-signin, &.mobile-navigation-signup {
					a {
						display: block;
						border:1px solid $primary;
						color:$primary;

						width: 100%;
						border-radius: 8px;
						text-align: center;
						padding: 0.5rem 1rem;
					}
				}

				&.mobile-copyright-privacy {
					display: flex;
					justify-content: space-between;
					font-size:0.8rem;
					color:$gray-500;
					
					a {
						color:$gray-500;
						text-decoration: underline;	
					}
					span {
						color: $gray-500;
					}
				}
				&.mobile-navigation-social-media {
					font-size: 1.25rem;
					a {
						margin-right: 1rem;
					}
				}
				svg {

				}
			}
		}


		.icon-placeholder {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			flex: 40px 0;
			margin-right: 20px;
		}

		.text-placeholder {
			border-radius: 5px;
			width: $mobile_nav_width;
			height: 20px;
			flex: 1;
		}

		.refresh {
			padding: 10px;
			position: absolute;
			background: rgba(0, 0, 0, 0.4);
			border-radius: 10px;
			width: 20px;
			height: 20px;
			top: 10px;
			right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
}