@import "~bootstrap/scss/functions";
@import './variables.scss';

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";

@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

/*@import "~bootstrap/scss/code";*/

@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";

/*
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron"; 
*/
//@import "~bootstrap/scss/alert";
/*
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "toasts";*/
@import "~bootstrap/scss/modal";
/*@import "tooltip";
@import "popover";
@import "carousel";*/
//@import "~bootstrap/scss/spinners";
//@import "~bootstrap/scss/utilities";
/*@import "print";*/

@import './woocommerce.scss';

@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,700;1,700&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import './avenir.css';

@import './gutenberg-new.scss';

@import '@wordpress/components/build-style/style.css';

@import 'react-loading-skeleton/dist/skeleton.css';


html {
	height:100%;
}
html, body {
	font-family: 'Questrial', sans-serif;
	font-kerning: normal;
	overflow: hidden;
}
body {
	color: var(--rflix-foreground);
	background-color: var(--rflix-background);
	line-height: 2;
	height:100%;
}

#root {
	overflow-y: scroll;
	height: 100%;
	position: relative;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 800;
	letter-spacing: -0.00em;
	margin-bottom: 1rem;
	font-family: 'Avenir', sans-serif;
}
p {
	line-height: 1.25;
}

a { 
	text-decoration:none;
	color: var(--rflix-primary);
}
.text-right { text-align:right; }

mark { padding: 0; }

h1 { font-size:3.5rem; }
h2 { font-size:3rem; }
h2.is-style-home-underline { font-size:3.5rem; }
h3 { font-size:2.5rem; }
h4 { font-size:2rem; }
h5 { font-size:1.5rem; }
h6 { font-size:1.25rem; }

.entry-content { 
	h1,h2,h3 {
		margin-bottom: 3rem;
	}
}

input, label {
}

.components-base-control__label, .form-group label {
	font-size: 0.9rem;
}
.components-text-control__input, 
.components-text-control__input[type="text"], 
.components-text-control__input[type="tel"], 
.components-text-control__input[type="time"], 
.components-text-control__input[type="url"], 
.components-text-control__input[type="week"], 
.components-text-control__input[type="password"], 
.components-text-control__input[type="color"], 
.components-text-control__input[type="date"], 
.components-text-control__input[type="datetime"], 
.components-text-control__input[type="datetime-local"], 
.components-text-control__input[type="email"], 
.components-text-control__input[type="month"], 
.components-text-control__input[type="number"] {
	border-radius: $border-radius;
	border:1px solid var(--rflix-gray);
	padding:1rem 1.25rem;
	font-size:1rem;
}
input[type="checkbox"] {

}
input[type="checkbox"], input[type="radio"] { 
	width: 1.1rem;
	height: 1.1rem;
	vertical-align: -3px;
}
input[type="search"] {
	line-height: 2;
}
input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
select {
	line-height: 1rem;
	font-size: 1rem;
	width: 100%;
	border-radius: 0.5rem;
    border: 1px solid var(--rflix-gray);
    padding: 0.8rem 1rem;
}

.App {
	padding:0;
	min-height: calc( 100vh + 1px );
	display: flex;
	flex-direction: column;

	pre {
		color: var(--rflix-foreground);
	}
	button:focus {
		outline:none;
	}
	&.fixed-height {
		max-height: 100vh;
		.App-content {
			max-height: 100%;
			overflow-y: hidden;
			
			.transition-container {
				max-height: 100%;
				overflow-y: hidden;
				.signup-flow.terms {
					max-height: 100%;
					overflow-y: hidden;
				}
			}

		}
	}

	.App-header { }
	.App-content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		.transition-container {
			flex-grow: 1;
		}
		.react-loading-skeleton {
			opacity: 0.2;
		}
		.hero-banner h1 {
			margin: 3rem 0;
			padding: 0 0.5rem;

		}
		.hero-image {
			height: 600px;
			background-size: cover;
			background-position: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: var(--rflix-foreground);
			margin-bottom: 5rem;
			box-shadow: 0 100px 100px var(--rflix-background);
			background-color: rgba( var(--rflix-background-rgb), 0.8);
			h1 {
				font-size: 4rem;
			}
			p {
				max-width: 450px;
				margin:0 auto;
			}

		}
		
		.has-text-align-center {
			text-align: center;
		}
		.alignfull { 

		}
		.alignleft {
			float:none;
			margin-right: 0rem;
		}
		.alignright {
			float:none;
			margin-left: 0rem;
		}
		.entry-content {
			margin-bottom: 2rem;
		}

		.faq {
			p {
				margin-left: 2.2em;
				text-indent: -2.2em;
				&:first-letter {
					margin-right: 1em;
				}
			}
		}

		.page {
			padding-bottom: 4rem;
			& > .container {
				margin-top:3rem;
			}
		}

	}
	.App-footer { 


	}
}

.btn {
	padding: 0.625em 1.875em;
	height: auto;
    outline: none !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	transition: background 0.1s border-color 0.1s;
	background-repeat:no-repeat;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled):focus,
.btn-primary:not(:disabled):not(.disabled).active {
    color: var(--rflix-foreground);
 	background: var(--rflix-primary);
    border-color: var(--rflix-primary);
}
.btn-primary:hover {
 	background: var(--rflix-primary-darker);
    border-color: var(--rflix-primary-darker);
}
.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):focus, 
.btn-secondary:not(:disabled):not(.disabled).active {
    color: var(--rflix-foreground);
 	background: var(--rflix-secondary);
    border-color: var(--rflix-secondary);
}
.btn-secondary:hover {
    color: var(--rflix-foreground);
 	background: var(--rflix-secondary-darker);
    border-color: var(--rflix-secondary-darker);
}
.btn-tertiary,
.btn-tertiary:not(:disabled):not(.disabled):active,
.btn-tertiary:not(:disabled):not(.disabled):focus, 
.btn-tertiary:not(:disabled):not(.disabled).active {
    color: var(--rflix-foreground);
 	background: var(--rflix-tertiary);
    border-color: var(--rflix-tertiary);
}
.btn-tertiary:hover {
    color: var(--rflix-foreground);
 	background: var(--rflix-tertiary-darker);
    border-color: var(--rflix-tertiary-darker);
}



.btn-link {
	border:none;
}
.alert {
	line-height: 1.2;
}

@include media-breakpoint-up(md) { 
	.App {
		.App-content {
			.alignleft {
				float:left;
				margin-right: 1rem;
			}
			.alignright {
				float:right;
				margin-left: 1rem;
			}
		}
	}
	.mobile-only {
		display: none;
	}
}
@include media-breakpoint-down(sm) { 
	.desktop-only {
		display: none;
	}
	.btn {
		padding: 0.625em 1.25em;
		height: auto;
	}
}
/*/////////////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////////////*/

.ratio-16x9 {
	padding-top: 56.25%;
	position:relative;
	height: 0;
	overflow: hidden;
}

.home-latest-videos {
	list-style: none;
	padding:0;
	display: flex;
	flex-wrap: wrap;	
	
	li {
		flex-basis: 14.285%;
		max-width: 14.285%;
		padding:0.33rem;
		div {
			border-radius: 8px;
			padding-top:165%;
			position: relative;

			height: 6.675rem;
			height: 0;
			background: var(--rflix-foreground);
			overflow: hidden;
			text-align: center;
			img {
				position: absolute;
				top:0;
				height:100%;
				width: auto;
				max-width: initial;
				left: 50%;
  				transform: translateX(-50%);
			}
		}
	}
}

.service-notice {
	display: block;
	background-color: $secondary;
	text-align: center;
	padding: 0.25rem;
	border:none;	
	/*
	border-top:1px solid $foreground;
	border-bottom:1px solid $foreground;
	*/
	font-weight: bold;
	font-size: 0.8em;
	color: $foreground;
	transition: background-color 0.2s;
	&:hover {
		background-color: darken($secondary, 5%);
	}
}

.is-style-primary-fade-to-left { background-image: linear-gradient(to left, transparent, var(--rflix-primary)); }
.is-style-primary-fade-to-right { background-image: linear-gradient(to right, transparent, var(--rflix-primary)); }
.is-style-secondary-fade-to-left { background-image: linear-gradient(to left, transparent, var(--rflix-secondary) ); }
.is-style-secondary-fade-to-right { background-image: linear-gradient(to right, transparent, var(--rflix-secondary)); }
.is-style-tertiary-fade-to-left { background-image: linear-gradient(to left, transparent, var(--rflix-tertiary )); }
.is-style-tertiary-fade-to-right { background-image: linear-gradient(to right, transparent, var(--rflix-tertiary )); }



.wp-block-image img {
	height:auto;
}
.is-style-round-corners {
	border-radius: $border-radius;

}

.wp-block-columns {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.wp-block-columns.alignfull {
	max-width: 100%;
}

.wp-block-group:not(.alignfull) {
	max-width: 1200px;
	padding:0 0.5em;
	margin:0 auto;
}

.font-size-bigger {
	font-size:120%;
}


.has-black-color {
	color:black;
}
.has-white-color {
	color:white;
}
.has-white-background-color {
	background-color:white;
}
.has-black-background-color {
	background-color:black;
}


.has-rflix-primary-color {
	color: var(--rflix-primary);
}
.has-rflix-secondary-color {
	color: var(--rflix-secondary);
}
.has-rflix-tertiary-color {
	color: var(--rflix-tertiary);
}

.has-rflix-primary-background-color {
	background-color: var(--rflix-primary);
}
.has-rflix-secondary-background-color {
	background-color: var(--rflix-secondary);
}
.has-rflix-tertiary-background-color {
	background-color: var(--rflix-tertiary);
}

.btn-primary.has-rflix-primary-background-color {
	border-color: var(--rflix-primary);
}
.btn-primary.has-rflix-secondary-background-color {
	border-color: var(--rflix-secondary);	
}
.btn-primary.has-rflix-tertiary-background-color {
	border-color: var(--rflix-tertiary);
}


.has-rflix-gray-color {
	color:$gray;
}
.has-rflix-black-color {
	color:black;
}