@import '../../scss/variables.scss';

.App-footer {
	overflow-x:hidden;
	.footer-row-1 {
		margin-top:3rem;
		.footer-left, .footer-menu, .footer-right {
			a, svg { margin:0 0.5rem; }
			img { max-width: 100%; }
			text-align: center;
		}
		.footer-left {
			margin-bottom: 1rem;
			.site-branding {
				svg {
					.logo-solid-color {
						fill: #fff;
					}
				}
			}
		}
		.footer-right {
			a {
				color: var(--rflix-foreground);
			}
		}
		.footer-menu {
			.navigation {
				font-family: 'Julius Sans One', sans-serif;
				list-style: none;
				margin: 0;
				padding:0;
				display: flex;
				justify-content: center;
				align-items:center;
				display: none;
				li {
					flex-basis: 33.33%;
					a {
						display: inline-block;
						line-height: 1.2;
						font-size: 0.9rem;
						color: var(--rflix-foreground);
						&.active {
							text-decoration:underline;
						}
					}
				}
			}
		}
		.footer-right {

		}
		
	}
	.footer-row-2 {
		margin-bottom:2rem;
		.payment-icons {
			color:#DBE0E3;
			font-size: 1.5rem;
			a, svg { margin:0 0.5rem; }
			text-align: center;
		}
		.copyline {
			text-align: center;
			color:#DBE0E3;
			font-size:0.75rem;
			margin-left:-($grid-gutter-width/2);
			margin-right:-($grid-gutter-width/2);
			flex-grow: 1;
			max-width: initial;
			span {
				margin:1rem;
			}
			path {
				fill: #DBE0E3;
			}
		}
	}
	.development-functions {
		text-align: center;
		line-height: 1.2;

		* { 
			color:#DBE0E3;
			font-size:0.75rem;
			display: inline;
		}
	}
}
@include media-breakpoint-up(md) {
	.App-footer {
		padding: 1rem;

		.footer-row-1, .footer-row-2 {
			align-items: center;
		}

		.footer-row-1 {
			margin-bottom: 1.5625rem;
			.footer-left {
				text-align: left;
				margin-bottom: 0;
				a, svg { margin:0; }
			}
			.footer-menu {
				.navigation {
					justify-content: space-around;
					display: flex;
					li {
					}
				}
			}
			.footer-right {
				text-align: right;
				a, svg { margin:0 0 0 0.5rem; }
			}
		}
		.footer-row-2 {
			.payment-icons {
				text-align: left;
				a, svg { margin:0 0.5rem 0 0; }
			}
		}
	}
}