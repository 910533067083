@import "./variables.scss";

.woocommerce {
	.products {
		list-style: none;
		margin:0; 
		padding:0;
		display: flex;
		justify-content: center;
		li {
			text-align: center;
			padding:1em;
			margin:0 1em;
			border:1px solid #fff;
			width:320px;
			button {
				display: block;
				width: 100%;
			}
			&.hidden {
				display: none;
			}
		}
	}
	

	.single-product {
		.woocommerce-product-gallery {
			width: 300px;
			float: left;
			img { max-width: 100%; }
		}
		.summary {
			h1.product_title {
				font-size: 1em;
			}
			margin-left: -300px;
			padding-left: calc( 300px + 1em );
			float:left;
			.woocommerce-grouped-product-list {
				td { 
					label { margin-bottom:0; }
					height: 40px;
					min-height: 40px;
					vertical-align: middle; 
				}
				.woocommerce-grouped-product-list-item__quantity {
					min-width: 110px;
					.button {
						
					}
				}
				.woocommerce-grouped-product-list-item__label {

				}
				.woocommerce-grouped-product-list-item__price {

				}
			}
		}

		.related {
			clear:both;
		}
	}
	.button {
		@extend .btn !optional;
		@extend .btn-primary !optional;
	}
	.woocommerce-message {
		@extend .alert !optional;
		@extend .alert-success !optional;	
		.button {
			@extend .btn-success;
		}
	}
	.woocommerce-info {
		@extend .alert !optional;
		@extend .alert-info !optional;	
		.button {
			@extend .btn-info;
		}
	}		
	ul.woocommerce-error {
		list-style: none;
		margin:0;
		padding:0;
		li {
			@extend .alert !optional;
			@extend .alert-danger !optional;
			.button {
				@extend .btn-danger;
			}
		}
	}
}	